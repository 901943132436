    @media only screen and (min-width: 700px) {
        #app .foto-fundo-hero {
            bottom: -5%;
            right: -10%;
        }

        #app .hero-texto-container {
            width: 50%;
        }

        #app .foto-fundo-hero {
            max-height: 600px;
        }
    }